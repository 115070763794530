/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
// import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/graphs/gra007.svg'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/websites'
        icon='/media/icons/duotune/electronics/elc004.svg'
        title='Websites'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/services'
        icon='/media/icons/duotune/art/art002.svg'
        title='Services'
        fontIcon='bi-layers'
      />
      <SidebarMenuItem
        to='/maintenances'
        icon='/media/icons/duotune/general/gen062.svg'
        title='Maintenances'
        fontIcon='bi-layers'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Admin</span>
        </div>
      </div>
      <SidebarMenuItem to='/history' icon='/media/icons/duotune/files/fil001.svg' title='History' />
      <SidebarMenuItem
        to='/users'
        icon='/media/icons/duotune/communication/com006.svg'
        title='Users'
      />
      <SidebarMenuItem to='/tasks' icon='/media/icons/duotune/abstract/abs019.svg' title='Task' />
    </>
  )
}

export {SidebarMenuMain}
