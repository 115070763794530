import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'

import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {useThemeMode} from '../_metronic/partials'
import {AuthInit} from './modules/auth'

const Toaster = () => {
  const {mode} = useThemeMode()
  return <ToastContainer theme={mode === 'dark' ? 'dark' : 'light'} />
}

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            <MasterInit />
            <Toaster />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
