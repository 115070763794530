/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {useMemo, useState} from 'react'
import {max} from 'lodash'
import {useQuery} from 'react-query'
import {GraphData, StatisticsWidget3} from './partials/StatisticsWidget3'
import {cleanPrice, getIncomeMontlyStat, getLastMonthsStat} from '../core/_requests'

export function IncomeMonthlyWidget() {
  const [year, setYear] = useState<'last12' | 'last6' | string>('last12')
  const {data: stats} = useQuery(
    `stats-year-${year}`,
    () => {
      if (!year) return null
      if (year === 'last6') {
        return getLastMonthsStat(6)
      }
      if (year === 'last12') {
        return getLastMonthsStat(12)
      }
      return getIncomeMontlyStat(Number(year))
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const currentYear = new Date().getFullYear()

  const data: (GraphData & {max: number}) | undefined = useMemo(() => {
    if (!stats) return undefined
    const ids = ['last12', 'last6'].includes(year as string)
      ? Object.keys(stats.values)
      : Object.keys(stats.values).sort((a, b) => Number(a) - Number(b))
    return {
      caption: 'Income',
      labels: ids.map((id) => stats.labels[id]),
      values: ids.map((id) => cleanPrice(stats.values[id])),
      max: max(ids.map((id) => stats.values[id])),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats])

  return (
    <StatisticsWidget3
      className='card-xl-stretch mb-5 mb-xl-8'
      color='primary'
      title='Monthly income'
      description={
        <select
          className='form-select form-select-solid fw-bolder'
          data-kt-select2='true'
          data-placeholder='Select year'
          data-allow-clear='true'
          data-kt-user-table-filter='role'
          data-hide-search='true'
          onChange={(e) => setYear(e.target.value)}
          value={year}
        >
          <option value='last12'>Last 12 month</option>
          <option value='last6'>Last 6 month</option>
          <option value={currentYear + 1}>{currentYear + 1}</option>
          {Array(currentYear - 2012)
            .fill(0)
            .map((_, index) => (
              <option key={index} value={currentYear - index}>
                {currentYear - index}
              </option>
            ))}
        </select>
      }
      change={stats?.total || stats?.total === 0 ? `${Math.floor(stats?.total)}€` : 'loading...'}
      max={data?.max}
      data={data}
    />
  )
}
