import {Website} from '../app/modules/website/website-list/core/_models'
import firebase, {db, getCached} from './firebase'

export async function getWebSiteByRef(ref: any): Promise<Website> {
  const websiteRef = await getCached(ref)
  return {id: websiteRef.id, ...websiteRef.data()}
}

export async function getWebSiteRefByUrl(
  url: string
): Promise<firebase.firestore.DocumentReference | null> {
  const snapshots = await db.collection('sites').where('url', '==', url).get()
  if (snapshots.docs.length === 0) {
    return null
  }
  const websiteRef = await db.collection('sites').doc(snapshots.docs?.[0]?.id)
  return websiteRef
}

// export async function getWebSiteById(id): Promise<Website> {}
