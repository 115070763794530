import {
  addMonths,
  getUnixTime,
  compareAsc,
  endOfMonth,
  endOfYear,
  format,
  isAfter,
  isBefore,
  startOfMonth,
  startOfYear,
  addYears,
} from 'date-fns'
import {db, getCached, normalizeDate} from '../../../../services/firebase'
import {Service} from '../../service/service-list/core/_models'

const INITIAL_VALUE: GraphStatValue = {
  labels: {},
  values: {},
  total: 0,
}

type GraphStatValue = {
  total: number
  labels: {[key: string]: string}
  values: {[key: string]: number}
}

const reduceDefaultValue = (
  curr: GraphStatValue,
  value: {index: any; label: any; value?: number}
) => {
  return {
    ...curr,
    labels: {...curr.labels, [value.index]: value.label},
    values: {...curr.values, [value.index]: value.value !== undefined ? value.value : 0},
    total: cleanPrice(curr.total + (value.value || 0)),
  }
}

/**
 * GLOBAL STATS WIDGET
 */
export async function getGlobalStat(): Promise<{
  users: any
  services: any
  websites: any
  types: any
}> {
  const users = (await db.collection('users').where('role', '==', 'usr').get()).size
  const services = (
    await db
      .collection('services')
      .orderBy('archived')
      .where('archived', '!=', true)
      .where('stop_date', '==', null)
      .get()
  ).size
  const websites = (
    await db
      .collection('sites')
      .orderBy('archived')
      .where('archived', '!=', true)
      .where('stop_date', '==', null)
      .get()
  ).size
  const types = (await db.collection('services_types').orderBy('name').get()).docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }))
  return {users, services, websites, types}
}

/**
 * YEARLY STATS WIDGET
 */
export async function getIncomeYearlyStat(): Promise<GraphStatValue> {
  let snapshots = await getCached(
    db.collection('services').where('archived', '!=', true).orderBy('archived')
  )
  const currentYear = new Date().getFullYear()
  const defaultValue = Array(currentYear - 2013)
    .fill(0)
    .map((_, i) => ({index: currentYear - i, label: currentYear - i}))
    .reduce(reduceDefaultValue, JSON.parse(JSON.stringify(INITIAL_VALUE)))

  const data = snapshots.docs.reduce((curr: any, doc: any) => {
    const service = doc.data()
    const startDate = normalizeDate(service.start_date) || 0
    const year = Number(format(startDate, 'yyyy'))
    let firstYearDay = startOfYear(new Date(`01/01/${year}`))
    let lastYearDay = endOfYear(new Date(`01/01/${year}`))

    // compute service annually incomes (one invoice per year)
    let yearlyIncomes = computeServiceAnnuallyIncome(service, {
      firstYearDay,
      lastYearDay,
    })
    for (const statKey of Object.keys(yearlyIncomes.values)) {
      if (curr.values[year]) {
        curr.values[year] += yearlyIncomes.values[statKey]
      } else {
        curr.values[year] = yearlyIncomes.values[statKey]
        curr.labels[year] = year
      }
    }
    // compute service monthly incomes (one invoice every month)
    let monthlyIncomes = computeServiceMonthlyIncome(service, {
      firstYearDay,
      lastYearDay,
    })
    for (const statKey of Object.keys(monthlyIncomes.values)) {
      if (curr.values[year]) {
        curr.values[year] += monthlyIncomes.values[statKey]
      } else {
        curr.values[year] = monthlyIncomes.values[statKey]
        curr.labels[year] = year
      }
    }
    if (year + 1 <= currentYear) {
      firstYearDay = startOfYear(new Date(`01/01/${year + 1}`))
      lastYearDay = endOfYear(new Date(`01/01/${year + 1}`))
      yearlyIncomes = computeServiceAnnuallyIncome(service, {
        firstYearDay,
        lastYearDay,
      })
      for (const statKey of Object.keys(yearlyIncomes.values)) {
        if (curr.values[year + 1]) {
          curr.values[year + 1] += yearlyIncomes.values[statKey]
        } else {
          curr.values[year + 1] = yearlyIncomes.values[statKey]
          curr.labels[year + 1] = year + 1
        }
      }
      monthlyIncomes = computeServiceMonthlyIncome(service, {
        firstYearDay,
        lastYearDay,
      })
      for (const statKey of Object.keys(monthlyIncomes.values)) {
        if (curr.values[year + 1]) {
          curr.values[year + 1] += monthlyIncomes.values[statKey]
        } else {
          curr.values[year + 1] = monthlyIncomes.values[statKey]
          curr.labels[year + 1] = year + 1
        }
      }
    }

    // curr.values[year] += cleanPrice(service.price)
    // return {...curr, total: curr.total + cleanPrice(service.price)}
    return curr
  }, defaultValue)

  return data
}

/**
 * MONTHLY STATS WIDGET (LAST MONTHS)
 */
export async function getLastMonthsStat(nbMonth = 12) {
  const firstMonth = addMonths(new Date(), -nbMonth)
  const firstMonthDay = startOfMonth(firstMonth)
  const lastMonthDay = endOfMonth(addMonths(new Date(), -1))

  // get default value for each month
  const defaultValue = Array(nbMonth)
    .fill(0)
    .map((_, i) => {
      const currDate = addMonths(new Date(), -(i + 1))
      return {index: format(currDate, 'yyyyMM'), label: format(currDate, 'MMM yyyy'), value: 0}
    })
    .reduce(reduceDefaultValue, JSON.parse(JSON.stringify(INITIAL_VALUE)))

  // retreive services for the period
  let snapshots = await getCached(
    db
      .collection('services')
      .where('start_date.seconds', '>=', getUnixTime(addYears(firstMonthDay, -1)))
      .where('start_date.seconds', '<=', getUnixTime(lastMonthDay))
      // .where('archived', '!=', true)
      .orderBy('start_date.seconds')
    // .orderBy('archived')
  )

  const data = snapshots.docs.reduce((curr: any, doc: any) => {
    const service = doc.data()
    return reduceComputeServiceAccumulaor(curr, service, {
      firstYearDay: firstMonthDay,
      lastYearDay: lastMonthDay,
    })
  }, defaultValue)
  return data
}

/**
 * MONTHLY STATS WIDGET (PER YEAR)
 */
export async function getIncomeMontlyStat(
  year = new Date().getFullYear()
): Promise<GraphStatValue> {
  const lastFirstYearDay = startOfYear(new Date(`01/01/${year - 1}`))
  const firstYearDay = startOfYear(new Date(`01/01/${year}`))
  const lastYearDay = endOfYear(new Date(`01/01/${year}`))

  let snapshots = await getCached(
    db
      .collection('services')
      .where('start_date.seconds', '>=', getUnixTime(lastFirstYearDay))
      .where('start_date.seconds', '<=', getUnixTime(lastYearDay))
      // .where('archived', '!=', true)
      .orderBy('start_date.seconds'),
    // .orderBy('archived')
    true
  )

  const defaultValue = Array(12)
    .fill(0)
    .map((_, i) => {
      const currDate = addMonths(firstYearDay, i)
      return {
        index: format(currDate, 'yyyyMM'),
        label: format(currDate, 'MMM yyyy'),
        value: 0,
      }
    })
    .reduce(reduceDefaultValue, JSON.parse(JSON.stringify(INITIAL_VALUE)))

  const data = snapshots.docs.reduce((curr: any, doc: any) => {
    const service = doc.data()
    return reduceComputeServiceAccumulaor(curr, service, {firstYearDay, lastYearDay})
  }, defaultValue)
  return data
}

const computeServiceAnnuallyIncome = (
  service: Service,
  {firstYearDay, lastYearDay}: {firstYearDay: Date; lastYearDay: Date}
): GraphStatValue => {
  let incomes = JSON.parse(JSON.stringify(INITIAL_VALUE))
  if (
    (service.billingPeriod && service.billingPeriod !== 'annually') ||
    !isServiceIncomeDateBetween(service, firstYearDay, lastYearDay)
  ) {
    return incomes
  }
  const startDate = normalizeDate(service.start_date) || 0
  const month = format(startDate, 'MMM')
  const year = format(startDate, 'yyyy')
  const index = format(startDate, 'yyyyMM')
  incomes.values[index] = cleanPrice(service.price)
  incomes.labels[index] = `${month} ${year}`
  incomes.total = cleanPrice(service.price)
  return incomes
}

const computeServiceMonthlyIncome = (
  service: Service,
  {firstYearDay, lastYearDay}: {firstYearDay: Date; lastYearDay: Date}
): GraphStatValue => {
  if (service.billingPeriod !== 'monthly') {
    return JSON.parse(JSON.stringify(INITIAL_VALUE))
  }
  const startDate = normalizeDate(service.start_date) || 0
  return Array(12)
    .fill(0)
    .map((_, i) => {
      const currDate = addMonths(startDate, i)
      if (
        isBefore(currDate, firstYearDay) ||
        isAfter(currDate, lastYearDay) ||
        (service.stop_date && isAfter(currDate, normalizeDate(service.stop_date) || 0))
      ) {
        return {
          index: '',
          label: '',
          value: 0,
        }
      }
      return {
        index: format(currDate, 'yyyyMM'),
        label: format(currDate, 'MMM yyyy'),
        value: cleanPrice((service.price || 0) / 12),
      }
    })
    .filter((value) => value.label !== '')
    .reduce(reduceDefaultValue, JSON.parse(JSON.stringify(INITIAL_VALUE)))
}

const reduceComputeServiceAccumulaor = (
  curr: GraphStatValue,
  service: Service,
  {firstYearDay, lastYearDay}: {firstYearDay: Date; lastYearDay: Date}
) => {
  if (!!service.archived) {
    return curr
  }
  // compute service annually incomes (one invoice per year)
  const yearlyIncomes = computeServiceAnnuallyIncome(service, {firstYearDay, lastYearDay})
  for (const statKey of Object.keys(yearlyIncomes.values)) {
    if (curr.values[statKey]) {
      curr.values[statKey] += yearlyIncomes.values[statKey]
    } else {
      curr.values[statKey] += yearlyIncomes.values[statKey]
      curr.labels[statKey] = yearlyIncomes.labels[statKey]
    }
  }
  curr.total += yearlyIncomes.total

  // compute service monthly incomes (one invoice every month)
  const monthlyIncomes = computeServiceMonthlyIncome(service, {firstYearDay, lastYearDay})
  for (const statKey of Object.keys(monthlyIncomes.values)) {
    if (curr.values[statKey]) {
      curr.values[statKey] += monthlyIncomes.values[statKey]
    } else {
      curr.values[statKey] += monthlyIncomes.values[statKey]
      curr.labels[statKey] = monthlyIncomes.labels[statKey]
    }
  }
  curr.total += monthlyIncomes.total
  return curr
}

const isServiceIncomeDateBetween = (
  service: Service,
  fromDay: Date,
  toDay: Date,
  options?: {isRenewDate?: boolean; ignoreBeforeStartDate?: boolean}
) => {
  const stopDate = normalizeDate(service.stop_date)
  const startDate = normalizeDate(service.start_date)
  const renewalDate = normalizeDate(service.renewal_date)
  if (stopDate) {
    // not active (stop date before interval, in the past)
    if (compareAsc(stopDate, fromDay) <= 0) return false
    // not active (stop date into interval)
    if (compareAsc(stopDate, fromDay) >= 0 && compareAsc(stopDate, toDay) <= 0) return false
  }
  // not active (start date in the future)
  if (isAfter(startDate as Date, toDay)) {
    return false
  }
  if (!options?.isRenewDate) {
    // not active (start date before interval)
    if (!options?.ignoreBeforeStartDate && isBefore(startDate as Date, fromDay)) {
      // before the start of month/year
      return false
    }
    if (compareAsc(startDate as Date, toDay) <= 0) {
      // before the end of month/year
      return true
    }
  } else {
    // not active (start date before interval)
    if (isBefore(renewalDate as Date, fromDay)) {
      // before the start of month/year
      return false
    }
    if (compareAsc(renewalDate as Date, toDay) <= 0) {
      // before the end of month/year
      return true
    }
  }

  return false
}

export const cleanPrice = (price: number) => Math.floor((price || 0) * 100) / 100
