/* eslint-disable jsx-a11y/anchor-is-valid */
import {format} from 'date-fns'
import React from 'react'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'
import {useQuery} from 'react-query'
import {Link} from 'react-router-dom'
import {getServices} from '../../service/service-list/core/_requests'

type Props = {
  className?: string
}

const ServicesExpiredWidget: React.FC<Props> = ({className}) => {
  const {
    data: services,
    isError,
    isFetching,
  } = useQuery(
    `stats-torenew`,
    async () => {
      return (await getServices({status: 'toRenew'})).data
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header align-items-center border-0 mt-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='fw-bold mb-2 text-dark'>Expired services</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body pt-5'>
        {/* begin::Timeline */}
        <div className='timeline-label'>
          {isFetching && 'Loading...'}
          {isError && 'Error...'}
          {!isFetching && services?.length === 0 && 'No services to renew'}
          {!isFetching &&
            services?.map?.((service) => (
              <div key={service.id} className='timeline-item'>
                {/* begin::Label */}
                <OverlayTrigger
                  placement='bottom'
                  overlay={
                    <Tooltip id='service-tooltip'>
                      {format(new Date(service.renewal_date as string), 'yyyy/MM/dd')}
                    </Tooltip>
                  }
                >
                  <div className='timeline-label fw-bold text-gray-800 fs-6'>
                    {format(new Date(service.renewal_date as string), 'MM/dd')}
                  </div>
                </OverlayTrigger>
                {/* end::Label */}
                {/* begin::Badge */}
                <div className='timeline-badge'>
                  <i className='fa fa-genderless text-danger fs-1'></i>
                </div>
                {/* end::Badge */}
                {/* begin::Desc */}
                <div className='timeline-content fw-bold text-gray-800 ps-3'>
                  <Link to={`/services/${service.id}/edit`} className='text-primary'>
                    {service.name}
                  </Link>
                  <br />
                  <Link
                    className='text-muted'
                    to={
                      service?.website?.name && service?.website?.url
                        ? `/websites/edit?url=${service?.website?.url}`
                        : '/websites'
                    }
                  >
                    {service.website?.name || 'no website'}
                  </Link>
                </div>
                {/* end::Desc */}
              </div>
            ))}
        </div>
        {/* end::Timeline */}
      </div>
      {/* end: Card Body */}
    </div>
  )
}

export {ServicesExpiredWidget}
