/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {ServicesExpiringSoonWidget} from '../../modules/stats/widget/ServicesExpiringSoonWidget'
import {ServicesExpiredWidget} from '../../modules/stats/widget/ServicesExpiredWidget'
import {GlobalStatWidget} from '../../modules/stats/widget/GlobalStatWidget'
import {IncomeMonthlyWidget} from '../../modules/stats/widget/IncomeMonthlyWidget'
import {IncomeYearlyWidget} from '../../modules/stats/widget/IncomeYearlyWidget'

const DashboardPage: FC = () => (
  <div className='row'>
    <div className='col-lg-6 col-xxl-4'>
      <GlobalStatWidget
        className='card-xl-stretch mb-xl-8'
        chartColor='danger'
        chartHeight='200px'
        strokeColor='#cb1e46'
      />
    </div>
    <div className='col-lg-6 col-xxl-8'>
      <IncomeMonthlyWidget />
      <IncomeYearlyWidget />
    </div>
    {/* <div className='col-lg-6 col-xxl-4'>
    </div> */}
    <div className='row'>
      <div className='col-lg-6'>
        <ServicesExpiredWidget className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
      <div className='col-lg-6'>
        <ServicesExpiringSoonWidget className='card-xxl-stretch mb-5 mb-xl-10' />
      </div>
    </div>
  </div>
)

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
