import {AuthModel, UserModel} from './_models'

import firebase, {auth, db, functions} from '../../../../services/firebase'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

export async function getUserByEmail(email: string): Promise<UserModel | null> {
  try {
    const response = await functions.httpsCallable('mainWp')({ressource: 'clients', email})
    return response.data
  } catch (err) {
    console.warn(`Auth user not found in mainWP (${email})`, err)
  }

  const snapshots = await db.collection('users').where('email', '==', email).limit(1).get()

  const getData = (doc: firebase.firestore.QueryDocumentSnapshot): UserModel => {
    const user: any = doc.data()
    return {id: doc.id, ...user}
  }

  const userData = snapshots.docs.length > 0 ? getData(snapshots.docs[0]) : null
  return userData
}

// Server should return AuthModel
export async function login(email: string, password: string): Promise<AuthModel> {
  const credential = await auth.signInWithEmailAndPassword(email, password)
  const user = await getUserByEmail(email)
  // if (!user) {
  //   throw new Error(`Utilisateur inconnu dans firestore (${email})`)
  // }
  // if (!user.firstname || !user.company) {
  //   throw new Error(`Utilisateur incomplet dans firestore (${email})`)
  // }

  // Signed in
  return {credential, user}
  // return axios.post<AuthModel>(LOGIN_URL, {
  //   email,
  //   password,
  // })
}

// Server should return AuthModel
// export function register(
//   email: string,
//   firstname: string,
//   lastname: string,
//   password: string,
//   password_confirmation: string
// ) {
//   return axios.post(REGISTER_URL, {
//     email,
//     first_name: firstname,
//     last_name: lastname,
//     password,
//     password_confirmation,
//   })
// }

// Server should return object => { result: boolean } (Is Email in DB)
// export function requestPassword(email: string) {
//   return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
//     email,
//   })
// }

// export function getUserByToken(token: string) {
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token: token,
//   })
// }
