/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import {useMemo} from 'react'
import {max} from 'lodash'
import {useQuery} from 'react-query'
import {cleanPrice, getIncomeYearlyStat} from '../core/_requests'
import {GraphData, StatisticsWidget3} from './partials/StatisticsWidget3'

export function IncomeYearlyWidget() {
  const {data: stats} = useQuery(
    `stats-by-year`,
    () => {
      return getIncomeYearlyStat()
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  const data: (GraphData & {max: number | undefined}) | undefined = useMemo(() => {
    if (!stats) return undefined
    const ids = Object.keys(stats.values)
    return {
      caption: 'Income',
      labels: ids.map((id) => stats.labels[id]),
      values: ids.map((id) => cleanPrice(stats.values[id])),
      max: max(ids.map((id) => stats.values[id])),
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stats])

  return (
    <StatisticsWidget3
      className='card-xl-stretch mb-5 mb-xl-8'
      color='primary'
      title='Yearly income'
      description=''
      change={!data ? 'loading...' : undefined}
      data={data}
      max={data?.max}
    />
  )
}
