import {orderBy, pick} from 'lodash'
import {db, denormalizeDate, getCached, normalizeDate} from '../../../../../services/firebase'
import {ID} from '../../../../../_metronic/helpers'
import {getServices, updateService} from '../../../service/service-list/core/_requests'
import {getClient, getClients, getSite, getSites} from '../../../../../services/mainWP'
import {Website} from './_models'
import {getWebSiteRefByUrl, getWebSiteByRef} from '../../../../../services/website'
import {getMaintenances} from '../../../maintenance/maintenance-list/core/_requests'

// const PAGE_SIZE = 50

const normalize = (website: any) => {
  return {
    ...website,
    start_date: normalizeDate(website.start_date),
    stop_date: normalizeDate(website.stop_date),
    maintenance_date: normalizeDate(website.maintenance_date),
    mainwpLastUpdate: normalizeDate(website.mainwpLastUpdate),
  }
}

const denormalize = (website: Partial<Website>) => {
  const updateData = JSON.parse(
    JSON.stringify({
      ...website,
      id: undefined,
      clientData: undefined,
      start_date: denormalizeDate(website.start_date),
      stop_date: denormalizeDate(website.stop_date),
      maintenance_date: denormalizeDate(website.maintenance_date),
    })
  )
  return pick(updateData, ['id', ...Object.keys(website)])
}

const getData = async (doc: any, clients?: any[]) => {
  const website = doc.data()
  if (website.clientId) {
    if (clients) {
      website.client = clients.find((client) => client.client_id === website.clientId)
    } else {
      website.client = await getClient({id: website.clientId})
    }
  } else if (website.client && website.client !== '/users/[object Object]') {
    const clientRef = await getCached(website.client)
    website.client = {id: clientRef.id, ...clientRef.data()}
  }
  return {id: doc.id, ...normalize(website)}
}

export async function getWebsites(
  filter: {
    searchText?: string
    status?: 'active' | 'archived' | 'stopped' | 'all'
    noLimit?: boolean
    page?: number
  },
  sort?: {field?: string; order?: 'asc' | 'desc'},
  lastId?: ID
) {
  let query: any = db.collection('sites')

  // if (filter?.searchText) {
  //   query = query
  //     .where('name', '>=', filter.searchText)
  //     .where('name', '<', filter.searchText + '\uf8ff')
  // }
  if (filter?.status === 'active') {
    query = query.orderBy('archived')
    query = query.where('archived', '!=', true)
    query = query.where('stop_date', '==', null)
  } else if (filter?.status === 'archived') {
    query = query.orderBy('archived')
    query = query.where('archived', '!=', false)
  } else if (filter?.status === 'stopped') {
    query = query.orderBy('stop_date')
    query = query.where('stop_date', '!=', null)
    query = query.where('archived', '==', false)
  }

  query = query.orderBy(sort?.field || 'name', sort?.order || 'asc')

  if (lastId && !filter?.searchText) {
    const doc = await db.collection('sites').doc(lastId).get()
    // console.log(lastId, doc.exists);
    query = query.startAfter(doc)
  }
  // if (!filter?.noLimit) {
  //   query = query.limit(PAGE_SIZE)
  // }
  const snapshots = await query.get()

  const clients = await getClients()

  let websites = (await Promise.all(
    snapshots.docs.map((doc: any) => getData(doc, clients))
  )) as Website[]
  const mainWpSites = (await getSites())
    .filter(
      (site) =>
        !filter?.searchText || site.name.toLowerCase().includes(filter.searchText.toLowerCase())
    )
    .map((site) => {
      const website = websites.find((website) => website.url === site.url)
      return {
        ...website,
        name: site.name,
        url: site.url,
        siteId: site.id,
        hosted_on: site.wpgroups
          ?.split(',')
          .filter((tag) => tag !== 'live')
          .join(','),
      }
    })
    .filter((site: Partial<Website>) => !filter?.status || filter.status === 'all' || !!site.id)

  if (filter.searchText) {
    websites = websites.filter((site: Website) =>
      // @ts-ignore
      site.name.toLowerCase().includes(filter.searchText.toLowerCase())
    )
  }
  const data = orderBy(
    [
      ...mainWpSites,
      ...websites.filter((website) => !mainWpSites.find((site) => site.url === website.url)),
    ],
    [sort?.field || 'name'],
    [sort?.order || 'asc']
  )
  return {
    data,
    payload: {
      message: undefined,
      errors: undefined,
      pagination: {
        page: 1, //filter.page || Math.ceil(snapshots.size / PAGE_SIZE),
        items_per_page: data.length, // PAGE_SIZE,
        isLast: true, //snapshots.size < PAGE_SIZE,
        isNextPage: false, //!!lastId,
      },
    },
  }
}

export async function getWebsite({url}: {url?: string}, onlyFirestore?: boolean): Promise<Website> {
  let website: Partial<Website>
  if (url) {
    const site = onlyFirestore ? null : await getSite(url)
    const snapshots = await db.collection('sites').where('url', '==', url).get()
    const websites = (await Promise.all(
      snapshots.docs.map((doc: any) => getData(doc))
    )) as Website[]
    if (!site && websites.length === 0) {
      throw new Error('Website not found')
    }
    if (websites.length > 0) {
      website = websites[0]
    } else {
      website = {}
    }
    website = {
      ...website,
      ...(site
        ? {
            siteId: site.id,
            clientId: site.client_id,
            url: site.url,
            name: site.name,
            hosted_on: site.wpgroups
              ?.split(',')
              .filter((tag) => tag !== 'live')
              .join(','),
          }
        : {}),
    } as Website
  } else {
    console.warn('Invalid website url', url)
    throw new Error('Invalid website url')
  }

  if (website.clientId) {
    website.client = await getClient({id: website.clientId})
  } else if (!website.client) {
    website.client = null
  }
  return website as Website
}

export async function addWebsite(values: Partial<Website>) {
  const websiteData = denormalize(values)
  const websiteRef = await getWebSiteRefByUrl(values.url as string)
  if (websiteRef) {
    throw new Error('URL_ALREADY_EXISTS')
  }

  // if (values.client) {
  //   websiteData.client = db.doc(`users/${values.client}`)
  // } else {
  websiteData.client = null
  // }

  await db.collection('sites').add({...websiteData, archived: false})

  return values.url
}

export async function updateWebsite(url: string, values: Partial<Website>) {
  const websiteData = denormalize(values)
  if (values.client) {
    websiteData.client = db.doc(`users/${values.client}`)
  }

  const websiteRef = await getWebSiteRefByUrl(url)
  if (!websiteRef) {
    throw new Error('Website not found ' + url)
  }
  const oldWebsiteData = await getWebSiteByRef(websiteRef)
  const archivedUpdated = !oldWebsiteData.archived && values.archived
  const stopDateUpdated = !oldWebsiteData.stop_date && values.stop_date
  await db.collection('sites').doc(websiteRef.id).update(websiteData)

  if (archivedUpdated || stopDateUpdated) {
    const data = await getServices({websiteUrl: url, noLimit: true})
    for (const service of data.data || []) {
      if (archivedUpdated) {
        await updateService(service.id as string, {
          archived: values.archived,
        })
      }
      if (stopDateUpdated && !service.stop_date) {
        await updateService(service.id as string, {
          stop_date: values.stop_date,
        })
      }
    }
  }
}

export async function bacthUpdateWebsites() {
  const data = await getWebsites({noLimit: true})
  console.log('bacthUpdateWebsites', data.data.length)
  for (const site of data.data || []) {
    if (site.url && !site.maintenance_date) {
      const maintenances = await getMaintenances({websiteUrl: site.url})
      const lastMaint = maintenances.data?.reduce((acc: string | null, curr: any) => {
        if (!acc) {
          return curr.date.toISOString()
        }
        // @ts-ignore
        if (curr.date?.toISOString().localeCompare(acc) > 0) {
          return curr.date
        }
        return acc
      }, null)
      if (lastMaint) {
        console.log(site.url, new Date(lastMaint))
        try {
          await updateWebsite(site.url, {
            maintenance_date: new Date(lastMaint),
          })
        } catch (e) {
          console.log('Error', site.url, e)
        }
      }
    }
    // if (site.id && (!site.clientId || site.clientId === '0')) {
    //   const mainwp = await getWebsite({url: site.url})
    //   if (mainwp.clientId) {
    //     console.log('> UPDATE WEBSITE', site.url, mainwp.clientId)
    //     await updateWebsite(site.url, {
    //       clientId: mainwp.clientId,
    //     })
    //   } else if (!site.stop_date && !site.archived) {
    //     console.log(
    //       'Client not found website !',
    //       site.url,
    //       mainwp.clientId,
    //       site.stop_date?.toLocaleString(),
    //       site.archived
    //     )
    //   }
    // }
  }
  console.log('bacthUpdateWebsites: end')
}
