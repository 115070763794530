import {pick} from 'lodash'
import {db, denormalizeDate, getCached, normalizeDate} from '../../../../../services/firebase'
import {getWebSiteByRef, getWebSiteRefByUrl} from '../../../../../services/website'
import {Maintenance, MaintenancesQueryResponse} from './_models'
import {ID} from '../../../../../_metronic/helpers'

const PAGE_SIZE = 50

const normalize = (maintenance: any) => {
  return {
    ...maintenance,
    date: normalizeDate(maintenance.date),
  }
}

const denormalize = (maintenance: Partial<Maintenance>) => {
  const updateData = JSON.parse(
    JSON.stringify({
      ...maintenance,
      id: undefined,
      date: denormalizeDate(maintenance.date),
    })
  )
  return pick(updateData, ['id', ...Object.keys(maintenance)])
}

export const getMaintenances = async (
  filter: {
    websiteUrl?: string
    serviceId?: string
    status?: 'active' | 'archived' | 'all'
    noLimit?: boolean
    page?: number
  },
  lastId?: ID
): Promise<MaintenancesQueryResponse> => {
  // console.log('getMaintenances', filter)
  let query: any = db.collection('maintenances')
  if (filter?.status === 'active') {
    query = query.orderBy('archived')
    query = query.where('archived', '!=', true)
  } else if (filter?.status === 'archived') {
    query = query.orderBy('archived')
    query = query.where('archived', '!=', false)
  }
  if (filter?.websiteUrl) {
    const websiteRef = await getWebSiteRefByUrl(filter.websiteUrl)
    query = query.where('website', '==', websiteRef)
  }
  if (filter?.serviceId) {
    const serviceRef = await db.collection('services').doc(filter.serviceId)
    query = query.where('service', '==', serviceRef)
  }
  query = query.orderBy('date.seconds', 'desc')
  if (lastId) {
    const doc = await getCached(db.collection('maintenances').doc(lastId))
    query = query.startAfter(doc)
  }
  if (!filter?.noLimit) {
    query = query.limit(PAGE_SIZE)
  }
  const snapshots = await query.get()

  const getData = async (doc: any) => {
    const maintenance = doc.data()
    if (maintenance.website) {
      maintenance.website = await getWebSiteByRef(maintenance.website)
    }
    if (maintenance.service) {
      const serviceRef = await getCached(maintenance.service)
      maintenance.service = {id: serviceRef.id, ...serviceRef.data()}
      if (maintenance.service.website) {
        maintenance.service.website = await getWebSiteByRef(maintenance.service.website)
      }
    }
    return {id: doc.id, ...normalize(maintenance)}
  }

  return {
    data: (await Promise.all(snapshots.docs.map((doc: any) => getData(doc)))) as Maintenance[],
    payload: {
      message: undefined,
      errors: undefined,
      pagination: {
        page: filter.page || Math.ceil(snapshots.size / PAGE_SIZE),
        items_per_page: PAGE_SIZE,
        isLast: snapshots.size < PAGE_SIZE,
        isNextPage: !!lastId,
      },
    },
  }
}

export async function getMaintenance(id: string): Promise<Maintenance> {
  const snapshot = await db.collection('maintenances').doc(id).get()
  if (!snapshot.exists) {
    throw new Error('not found')
  }

  const maintenance: any = snapshot.data()
  if (maintenance.website) {
    const website = await getWebSiteByRef(maintenance.website)
    maintenance.website = website.id
  }
  if (maintenance.service) {
    const serviceRef = await getCached(maintenance.service)
    maintenance.service = serviceRef.id
  }
  return {id: snapshot.id, ...normalize(maintenance)}
}

export async function addMaintenance(values: Partial<Maintenance>) {
  const maintenanceData = denormalize(values)
  if (values.website) {
    maintenanceData.website = db.doc(`sites/${values.website}`)
  } else {
    maintenanceData.website = null
  }
  if (values.service) {
    maintenanceData.service = db.doc(`services/${values.service}`)
  } else {
    maintenanceData.service = null
  }
  const docRef = await db
    .collection('maintenances')
    .add({...maintenanceData, source: 'manual', archived: false})

  return docRef.id
}

export async function updateMaintenance(id: string, values: Partial<Maintenance>) {
  const maintenanceData = denormalize(values)
  if (values.website) {
    maintenanceData.website = db.doc(`sites/${values.website}`)
  }
  if (values.service) {
    maintenanceData.service = db.doc(`services/${values.service}`)
  }
  await db.collection('maintenances').doc(id).update(maintenanceData)
}
