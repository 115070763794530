import {addMonths, addYears, endOfDay, endOfYear, getUnixTime, startOfYear} from 'date-fns'
import {pick} from 'lodash'
import {
  db,
  denormalizeDate,
  functions,
  getCached,
  normalizeDate,
} from '../../../../../services/firebase'
import {Service, ServicesQueryResponse} from './_models'
import {getWebSiteByRef, getWebSiteRefByUrl} from '../../../../../services/website'
import {addHistory} from '../../../history/history-list/core/_requests'
import {getWebsite} from '../../../website/website-list/core/_requests'
import {History} from '../../../history/history-list/core/_models'

const PAGE_SIZE = 50

const normalize = (service: any) => {
  return {
    ...service,
    start_date: normalizeDate(service.start_date),
    stop_date: normalizeDate(service.stop_date),
    renewal_date: normalizeDate(service.renewal_date),
  }
}

const denormalize = (service: Partial<Service>) => {
  const updateData = JSON.parse(
    JSON.stringify({
      ...service,
      id: undefined,
      start_date: denormalizeDate(service.start_date),
      stop_date: denormalizeDate(service.stop_date),
      renewal_date: denormalizeDate(service.renewal_date),
    })
  )
  return pick(updateData, ['id', ...Object.keys(service)])
}

export async function getServices(
  filter: {
    status?: 'active' | 'archived' | 'stopped' | 'toRenew' | 'nextRenew'
    type?: string
    websiteUrl?: string
    year?: number
    noRenewalOrder?: boolean
    noLimit?: boolean
    page?: number
  },
  lastId?: any
): Promise<ServicesQueryResponse> {
  let query: any = db.collection('services')

  if (filter?.year) {
    query = query.where(
      'start_date.seconds',
      '>=',
      getUnixTime(startOfYear(new Date(`01/01/${filter.year}`)))
    )
    query = query.where(
      'start_date.seconds',
      '<=',
      getUnixTime(endOfYear(new Date(`01/01/${filter.year}`)))
    )
    query = query.orderBy('start_date.seconds')
  }
  if (filter?.status === 'active') {
    // query = query.orderBy('archived')
    query = query.where('archived', '==', false)
    query = query.where('stop_date', '==', null)
  } else if (filter?.status === 'archived') {
    query = query.orderBy('archived')
    query = query.where('archived', '==', true)
  } else if (filter?.status === 'stopped') {
    query = query.orderBy('stop_date')
    query = query.where('stop_date', '!=', null)
  } else if (filter?.status === 'toRenew') {
    query = query.orderBy('renewal_date.seconds', 'asc')
    query = query.where('renewal_date.seconds', '<=', getUnixTime(endOfDay(new Date())))
    query = query.where('stop_date', '==', null)
  } else if (filter?.status === 'nextRenew') {
    query = query.orderBy('renewal_date.seconds', 'asc')
    query = query.where('renewal_date.seconds', '>', getUnixTime(endOfDay(new Date())))
    query = query.where(
      'renewal_date.seconds',
      '<=',
      getUnixTime(addMonths(endOfDay(new Date()), 2))
    )
    query = query.where('stop_date', '==', null)
  }
  if (!filter.noRenewalOrder && !['toRenew', 'nextRenew'].includes(filter?.status || '')) {
    query = query.orderBy('renewal_date.seconds', 'asc')
  }
  if (filter?.type) {
    const typeRef = await db.collection('services_types').doc(filter.type)
    query = query.where('type', '==', typeRef)
  }
  if (filter?.websiteUrl) {
    const websiteRef = await getWebSiteRefByUrl(filter.websiteUrl)
    query = query.where('website', '==', websiteRef)
  } else {
    query = query.orderBy('website')
  }
  if (lastId) {
    const doc = await getCached(db.collection('services').doc(lastId))
    query = query.startAfter(doc)
  }
  if (!filter.noLimit) {
    query = query.limit(PAGE_SIZE)
  }
  const snapshots = await query.get()

  const getData = async (doc: any) => {
    const service = doc.data()
    if (service.website) {
      service.website = await getWebSiteByRef(service.website)
    }
    return {id: doc.id, ...normalize(service)}
  }

  return {
    data: (await Promise.all(snapshots.docs.map((doc: any) => getData(doc)))) as Service[],
    payload: {
      message: undefined,
      errors: undefined,
      pagination: {
        page: filter.page || Math.ceil(snapshots.size / PAGE_SIZE),
        items_per_page: PAGE_SIZE,
        isLast: snapshots.size < PAGE_SIZE,
        isNextPage: !!lastId,
      },
    },
  }
}

export async function getService(id: string): Promise<Service> {
  const snapshot = await db.collection('services').doc(id).get()
  if (!snapshot.exists) {
    throw new Error('not found')
  }

  const service: any = snapshot.data()
  if (service.websiteUrl) {
    service.website = service.websiteUrl
  } else if (service.website) {
    const website = await getWebSiteByRef(service.website)
    service.website = website.url
  }
  if (service.type) {
    const typeRef = await getCached(service.type)
    service.type = typeRef.id
  }
  return {id: snapshot.id, ...normalize(service)}
}

export async function addService(values: Partial<Service>, archived = false) {
  const serviceData = denormalize(values)
  if (values.website) {
    const websiteRef = await getWebSiteRefByUrl(values.website)
    if (!websiteRef) {
      throw new Error('Website not found' + values.website)
    }

    serviceData.websiteUrl = values.website
    serviceData.website = db.doc(`sites/${websiteRef.id}`)
  } else {
    serviceData.website = null
  }
  if (values.type) {
    serviceData.type = db.doc(`services_types/${values.type}`)
  } else {
    serviceData.type = null
  }

  const docRef = await db.collection('services').add({...serviceData, archived})
  addServiceHistory(values, 'service-created')
  return docRef.id
}

const addServiceHistory = async (oldService: Partial<Service>, type: History['type']) => {
  const website = await getWebsite({url: oldService.website}, true)
  addHistory({type, message: JSON.stringify(oldService), website: website.id})
}

export async function updateService(
  id: string,
  values: Partial<Service>,
  type?: 'RENEW' | 'STOP' | 'ARCHIVE'
) {
  let serviceData
  if (type === 'RENEW' && values.renewal_date) {
    // duplicate service
    const oldService = await getService(id)
    await addService(
      {
        ...oldService,
        ...values,
        maintenanceDuration: 0,
        lastInvoiceId: null,
        lastInvoiceFile: null,
        // @ts-ignore
        start_date: addYears(new Date(oldService.start_date), 1),
        stop_date: null,
        archived: false,
      },
      false
    )
    // archive old maintenances
    // const data = await getMaintenances({
    //   status: "active",
    //   serviceId: id,
    //   noLimit: true,
    // });
    // for (const maintenance of data.results) {
    //   await updateMaintenance(maintenance.id, {
    //     archived: true,
    //   });
    // }
    // archive old service
    serviceData = {
      // archived: true,
      // @ts-ignore
      stop_date: addYears(new Date(oldService.start_date), 1),
    }
  } else if (type === 'STOP') {
    serviceData = {
      stop_date: denormalizeDate(values.stop_date),
    }
  } else if (type === 'ARCHIVE') {
    serviceData = {
      archived: values.archived,
    }
  } else {
    // update
    serviceData = denormalize(values)
    if (values.website) {
      const websiteRef = await getWebSiteRefByUrl(values.website)
      if (!websiteRef) {
        throw new Error('Website not found ' + values.website)
      }
      serviceData.websiteUrl = values.website
      serviceData.website = db.doc(`sites/${websiteRef.id}`)
    }
    if (values.type) {
      serviceData.type = db.doc(`services_types/${values.type}`)
    }
  }
  const oldService = await getService(id)
  await db.collection('services').doc(id).update(serviceData)
  if (type === 'RENEW' && values.renewal_date) {
    addServiceHistory(oldService, 'service-renew')
  } else if (type === 'STOP') {
    addServiceHistory(oldService, 'service-stop')
  } else if (type === 'ARCHIVE') {
    addServiceHistory(oldService, 'service-archived')
  }
}

export async function duplicateService(id: string) {
  // duplicate service
  const oldService = await getService(id)
  return await addService(
    {
      ...oldService,
      maintenanceDuration: 0,
      lastInvoiceId: null,
      lastInvoiceFile: null,
      // @ts-ignore
      stop_date: addYears(new Date(oldService.start_date), 1),
    },
    true
  )
}

export async function sendRenewEmail(values: any) {
  return await functions.httpsCallable('sendRenewEmail')(values)
}

export async function getServicesTypes() {
  let query = db.collection('services_types').orderBy('name')
  const snapshots = await query.get()

  const getData = async (doc: any) => {
    const serviceType = doc.data()
    return {id: doc.id, ...serviceType}
  }

  return await Promise.all(snapshots.docs.map((doc) => getData(doc)))
}

export async function bacthUpdateServices() {
  const data = await getServices({noLimit: true, noRenewalOrder: true})
  for (const service of data.data || []) {
    if (!service.billingPeriod) {
      console.log('update service', service.id, {
        billingPeriod: service.website.billingPeriod || 'annually',
      })
      await updateService(service.id as string, {
        billingPeriod: service.website.billingPeriod || 'annually',
      })
    }
    // if (!service.renewal_date) {
    //   console.log('update service', service.id)
    //   await updateService(service.id as string, {
    //     renewal_date: new Date(),
    //   })
    // }
  }
}
