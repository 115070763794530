import firebase from 'firebase/app'
import 'firebase/auth'
// import "firebase/database";
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  //   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
})

export const auth = firebase.auth()
export const db = firebase.firestore()
export const functions = firebase.app().functions('europe-west1')
export const storage = firebase.storage()

// DEV MODE
// if (process.env.NODE_ENV === 'development') {
//   // https://firebase.google.com/docs/functions/local-emulator
//   auth.useEmulator('http://localhost:9099')
//   db.useEmulator('localhost', 8080)
//   functions.useEmulator('localhost', 5001)
//   storage.useEmulator('localhost', 9199)
// }

// INIT CONFIG
db.enablePersistence()

const storageRef = storage.ref()

export const uploadFile = async (id: string, file: any) => {
  const ref = storageRef.child(id)
  await ref.put(file, {
    cacheControl: 'public,max-age=86400',
    // contentLanguage: "en-US",
    customMetadata: {},
  })
  return id
}

export const removeFile = async (id: string) => {
  const ref = storageRef.child(id)
  return ref.delete()
}

export const normalizeDate = (date: any): Date | null => {
  return date?.seconds ? new Date(date.seconds * 1000) : null
}

export const denormalizeDate = (date: any): firebase.firestore.Timestamp | null | undefined => {
  if (date === null || date === undefined) {
    return null
  }
  return date ? firebase.firestore.Timestamp.fromDate(date) : undefined
}

export const getCached = async (query: any, noCache?: boolean) => {
  if (noCache) return await query.get()
  let ref
  try {
    ref = await query.get({source: 'cache'})
  } catch (e) {
    ref = await query.get()
  }
  return ref
}

export const clearCache = async () => {
  return firebase.firestore().clearPersistence()
}

export const isPermissionError = (error: any) => error?.code === 'permission-denied'

export default firebase
