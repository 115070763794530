import {pick} from 'lodash'
import {db, denormalizeDate, getCached, normalizeDate} from '../../../../../services/firebase'

import {History} from './_models'
import {getWebSiteByRef, getWebSiteRefByUrl} from '../../../../../services/website'
import {ID} from '../../../../../_metronic/helpers'
import {getUnixTime} from 'date-fns'

const PAGE_SIZE = 50

const normalize = (history: any) => {
  return {
    ...history,
    createdAt: normalizeDate(history?.createdAt),
  }
}

const denormalize = (history: any) => {
  const updateData = {
    ...JSON.parse(
      JSON.stringify({
        ...history,
        id: undefined,
        createdAt: denormalizeDate(history?.createdAt),
      })
    ),
  }
  return pick(updateData, ['id', ...Object.keys(history)])
}

export async function getHistory(
  filter: {
    websiteUrl?: string
    serviceId?: string
    fromDate?: string
    toDate?: string
    type?: History['type']
    noLimit?: boolean
    page?: number
  },
  lastId?: ID
) {
  let query = db.collection('history').orderBy('createdAt.seconds', 'desc')
  if (filter.type) {
    query = query.where('type', '==', filter.type)
  }
  if (filter.fromDate) {
    query = query.where('createdAt.seconds', '>=', getUnixTime(new Date(filter.fromDate)))
  }
  if (filter.toDate) {
    query = query.where('createdAt.seconds', '<=', getUnixTime(new Date(filter.toDate)))
  }
  if (filter?.websiteUrl) {
    const websiteRef = await getWebSiteRefByUrl(filter.websiteUrl)
    query = query.where('website', '==', websiteRef)
  }
  if (lastId) {
    const doc = await getCached(db.collection('history').doc(lastId))
    query = query.startAfter(doc)
  }
  if (!filter?.noLimit) {
    query = query.limit(PAGE_SIZE)
  }
  const snapshots = await query.get()

  const getData = async (doc: any) => {
    const history = doc.data()
    if (history.website) {
      history.website = await getWebSiteByRef(history.website)
    }
    if (history.service) {
      const serviceRef = await getCached(history.service)
      history.service = {id: serviceRef.id, ...serviceRef.data()}
      if (history.service.website) {
        history.service.website = await getWebSiteByRef(history.service.website)
      }
    }
    return {id: doc.id, ...normalize(history)}
  }

  return {
    data: (await Promise.all(snapshots.docs.map((doc: any) => getData(doc)))) as History[],
    payload: {
      message: undefined,
      errors: undefined,
      pagination: {
        page: 1,
        items_per_page: 50,
        isLast: true,
        isNextPage: false,
      },
    },
  }
}

export async function addHistory(values: Partial<History>) {
  const historyData = denormalize({createdAt: new Date(), ...values})
  if (values.website) {
    historyData.website = db.doc(`sites/${values.website}`)
  } else {
    historyData.website = null
  }
  if (values.service) {
    historyData.service = db.doc(`services/${values.service}`)
  } else {
    historyData.service = null
  }
  const docRef = await db.collection('history').add({...historyData})

  return docRef.id
}
