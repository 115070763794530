import {MainWPClient, MainWPSite} from '../app/modules/user/user-list/core/_models'
import {functions} from './firebase'

export async function getClients(): Promise<MainWPClient[]> {
  const response = await functions.httpsCallable('mainWp')({ressource: 'clients'})
  return response.data
}

export async function getClient({
  id,
  email,
}: {
  id?: string
  email?: string
}): Promise<MainWPClient | null> {
  try {
    const response = await functions.httpsCallable('mainWp')({ressource: 'clients', email, id})
    return response?.data
  } catch (e: any) {
    if (e.code !== 'not-found') {
      console.log(e)
    }
    return null
  }
}

export async function getSites(): Promise<MainWPSite[]> {
  const response = await functions.httpsCallable('mainWp')({ressource: 'sites'})
  return response.data
}

export async function getSite(url: string): Promise<MainWPSite | null> {
  try {
    const response = await functions.httpsCallable('mainWp')({ressource: 'sites', url})
    return response?.data
  } catch (e: any) {
    if (e.code !== 'not-found') {
      console.log(e)
    }
    return null
  }
}
